import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient,HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { UserData, Store, GetRatesRequest, Rate } from '../utils/types';
import { DataStorageService } from './data-storage.service';
import { SegmentService } from './segment.service';
import { sortRates } from '../utils/operations';
import { AuthService } from './listo-common/auth.service';

const API_URL = environment.apiUrl;
const ZC_API_URL = environment.zipCodeApiUrl;
const API_URL_V2 = environment.apiUrlV2;
const TRUSTAGE_API_URL = environment.tsApiUrl;
const TRUSTAGE_CLIENT_ID = environment.tsClientId;
const TRUSTAGE_USER_NAME = environment.tsUserName;
const TRUSTAGE_PASSWORD = environment.tsPassword;
const TRUSTAGE_OIDC = environment.tsOIDC;
const TRUSTAGE_PARTNER_API_KEY = environment.tsPartnerApiKey;
const PRODUCTION = environment.stage == 'production';

@Injectable({
  providedIn: 'root',
})
export class WebServicesService {
  constructor(
    private http: HttpClient,
    private storage: DataStorageService,
    private auth: AuthService,
  ) {}

  get options() {
    const headers = {
      'Content-Type': 'text/plain',
      'Access-Control-Allow-Origin': '*',
    };
    if (this.storage.getData()) {
      const { sid, cid } = this.storage.getData();
      if (sid) {
        headers['Listo-Affiliate-Source-Id'] = sid;
      }
      if (cid) {
        headers['Listo-Affiliate-Click-Id'] = cid;
      }
    }
    const segmentUserId = SegmentService.getId();
    if (segmentUserId) {
      headers['Listo-Analytics-Segment-User-Id'] = segmentUserId;
    }
    return {
      headers,
      withCredentials: true,
    };
  }

  getLimits() {
    return this.http.get<{
      age: number[],
      coverage: number[],
      guaranteedUniversalLength: number[],
      temporaryLength: number[],
    }>(
      `${API_URL}/limits`,
      this.options,
    );
  }

  getRates(data: GetRatesRequest) {
    return this.http.get<{
      customer_id: string;
      transaction_id: string;
      quotes: Rate[];
    }>(
      `${API_URL}/quote`,
      {
        ...this.options,
        params: {
          tobaccoUse: data.tobaccoUse ? '1' : '0',
          coverage: data.coverage.toString(),
          age: data.age.toString(),
          zipCode: data.zipCode.toString(),
          ...(data.length !== undefined && {
            length: data.length.toString(),
          }),
          insuranceType: data.insuranceType,
          gender: data.gender,
        },
      },
    ).pipe(map(
      (res) => ({
        ...res,
        quotes: res.quotes.sort(sortRates),
      }),
    ));
  }

  saveUserInfo(data: UserData) {
    return this.http.post(
      `${API_URL}/leads`,
      {
        ...data,
        weCall: data.contactTime === 'i_call',
        contactPreference: '',
      },
      this.options,
    );
  }

  getNearestStore(zipcode: number) {
    return this.http.get<{ store: Store }>(
      `${ZC_API_URL}/storefront/${zipcode}`,
    ).pipe(
      map(({ store }): Store => store),
    );
  }

  sendZapierData(data: UserData) {
    let agentEmail;
    if (this.auth.isAuthenticated) {
      agentEmail = this.auth.currentAgent.email;
    }
    return this.http.post(
      'https://hooks.zapier.com/hooks/catch/1949618/garlik/',
      {
        ...data.url_params,
        language: 'spanish',
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phoneNumber,
        agentEmail,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
  }

  catchApplicants(data: UserData) {
    const headers = this.createHeaders();
    const utm_campaign = this.getUTMCampaign();

    return this.http.post(`${API_URL_V2}/api/v1/life_applicants`,
      {
        applicant: {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phoneNumber,
          gender: data.gender,
          birthdate: data.birthdate,
          application_id_ts: data.applicationId,
          ts_url: data.tsUrl,
          utm: utm_campaign,
        },
      },
      {
        headers: headers,
        withCredentials: false,
      }
    );
  }

  updateApplicant(data: UserData) {
    const headers = this.createHeaders();
    const utm_campaign = this.getUTMCampaign();

    return this.http.put(`${API_URL_V2}/api/v1/life_applicants/update_applicant`,
      {
        applicant: {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phoneNumber,
          gender: data.gender,
          birthdate: data.birthdate,
          application_id_ts: data.applicationId,
          ts_url: data.tsUrl,
          utm: utm_campaign,
        },
      },
      {
        headers: headers,
        withCredentials: false,
      }
    );
  }

  markApplicantAsCompleted(data: UserData) {
    const headers = this.createHeaders();
    const utm_campaign = this.getUTMCampaign();

    return this.http.post(`${API_URL_V2}/api/v1/life_applicants/mark_completed`,
      {
        applicant: {
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phoneNumber,
          gender: data.gender,
          birthdate: data.birthdate,
          application_id_ts: data.applicationId,
          ts_url: data.tsUrl,
          utm: utm_campaign,
        },
      },
      {
        headers: headers,
        withCredentials: false,
      }
    );
  }

  getLocation(zipcode: string) {
    return this.http
      .get<any>( `${ZC_API_URL}/cities/${zipcode}`
        , {}
      ).toPromise();
  }

  getQuotes(access_token, data: UserData, coverage, maxCoverage, step) {
    
    const url = TRUSTAGE_API_URL + (PRODUCTION ? 'partnerapi/v1/quotes' : 'partnerapi-demo/v1/quotes');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + access_token,
      'Ocp-Apim-Subscription-Key': TRUSTAGE_PARTNER_API_KEY,
    });

    const birthdate = new Date(new Date(data.birthdate).setMinutes(new Date().getTimezoneOffset()));
    const year = birthdate.getFullYear();
    const month = String(birthdate.getMonth() + 1).padStart(2, '0');
    const day = birthdate.getDate().toString().padStart(2, '0');

    return this.http.post<{
      quotes: any[];
    }>(
      `${url}?client_id=${TRUSTAGE_CLIENT_ID}`,
      {
        "postalCode": data.zipCode,
        "birthDate": `${year}-${month}-${day}`,
        "gender": data.gender,
        "tobaccoUse": data.tobaccoUse,
        "channel": "Web",
        "minCoverage": coverage,
        // "maxCoverage": maxCoverage,
        "billingFrequency": "Monthly",
        "coverageIncrement": step
      },
      {
        headers: headers,
        withCredentials: false,
      }
    )
    .pipe(map(
      (res) => ({
        ...res,
        quotes: res.quotes,
      }),
    ));
  }

  getAccessToken() {
    const url = TRUSTAGE_API_URL +'openid-connect-authorization/token/V1/as/token.oauth2';

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Ocp-Apim-Subscription-Key': TRUSTAGE_OIDC,
    });

    const body = new HttpParams()
      .set('scope', 'openid profile address email')
      .set('username', TRUSTAGE_USER_NAME)
      .set('password', TRUSTAGE_PASSWORD)
      .set('grant_type', 'password');

    const params = new HttpParams();

    return this.http
      .post<any>(url, body.toString(), {
        headers: headers,
        params: params,
      }).toPromise();
  }

  apply(access_token, data: UserData, quote ) {
    const url = TRUSTAGE_API_URL + (PRODUCTION ? 'partnerapi/v1/applications' : 'partnerapi-demo/v1/applications');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + access_token,
      'Ocp-Apim-Subscription-Key': TRUSTAGE_PARTNER_API_KEY,
    });

    const birthdate = new Date(new Date(data.birthdate).setMinutes(new Date().getTimezoneOffset()));
    const year = birthdate.getFullYear();
    const month = String(birthdate.getMonth() + 1).padStart(2, '0');
    const day = birthdate.getDate().toString().padStart(2, '0');

    return this.http.post<{}>(
      `${url}?client_id=${TRUSTAGE_CLIENT_ID}`,
      {
          "productCode": quote.productCode, // required
          "coverageAmount": quote.coverage, // required
          "tobaccoUse": data.tobaccoUse,
          "channel": "Web", // required
          "billingMethod": "ACH", // required
          "billingFrequency": "Monthly", // required
          "applicant": { // required
              "firstName": data.firstName || 'anonymous',
              "middleName": "NA",
              "lastName": data.lastName,
              "postalCode": data.zipCode,
              "state": data.stateCode,
              "gender": data.gender,
              "birthDate" : `${year}-${month}-${day}`,
              "phoneNumber": data.phoneNumber ? data.phoneNumber.replace(/\D/g, '') : '',
              "emailAddress": data.email,
          }
      },
      {
        headers: headers,
        withCredentials: false,
      }
    )
    .pipe(map(
      (res) => ({
        ...res,
        application: res,
      }),
    ));
  }

  private getUTMCampaign(): string {
    const params = new URLSearchParams(window.location.search);
    return params.has('utm_campaign') ? params.get('utm_campaign') : '';
  }
  
  private createHeaders(): HttpHeaders {
    return new HttpHeaders().set('Content-Type', 'application/json');
  }
}

